* {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6{
  margin: 0;
}
#root {
  height: 100%;
}
.root-layout {
  height: 100%;
  background:#fff;
}
ul,
ol,
li{
  list-style: none;
  margin:0;
}