.modal {
  background-image: url("./assets/bg.png");
  background-color: transparent;
  height: 400px;
  background-repeat: no-repeat;
  background-size: contain;
  :global(.ant-modal-body){
    padding-top: 10px;
  }
}
.modal div {
  box-shadow: none;
  background: transparent;
  p{
    text-indent: 2em;
    margin-bottom: 0;
  }
}
.content{
  margin-top: 25px;
}
.header {
  margin-bottom: 3%;
  color: #fff;
  margin-top: 3%;
  line-height: 1.8;
  span{
    display: inline-block;
    background: #fff;
    border-radius: 2px;
    color: #ff6600;
    line-height: 1.2;
    padding:2px;
    margin: 0 2px;
  }
}
.btn {
  text-align: center;
  margin-top: 20px;
  display: block;
}
