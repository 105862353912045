.ml8 {
  margin-left: 8px;
}
.logo-header {
  padding: 0;
  text-align: center;
  .logo {
    width: 75%;
    line-height: 36px;
    display: inline-block;
    svg {
      vertical-align: middle;
    }
    path {
      fill: #fff;
    }
  }
}

.header {
  background: #fff;
}
.profile {
  :global(.ant-avatar) {
    margin-right: 8px;
  }
  a {
    color: rgba($color: #000000, $alpha: 0.65);
  }
}
.spin {
  min-height: 100vh;
  :global {
    .ant-spin-container {
      min-height: 100vh;
    }
  }
}
.offlineTip {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    margin-top: 20px;
    width: 700px;
  }
}